import { isKeyInType, Label, TableConfigType } from '@autone/ui';
import {
  Skeleton,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

// @ts-ignore TODO: type on a later pr
import { Compare, ComparePercent, Current } from '../features/table';
import store from '../redux/store';
import { RetailPerformanceData } from '../types';

const isCompareMetric = (string: string) =>
  ['wtd-compare', 'mtd-compare', 'today-compare', 'ytd-compare'].includes(
    string,
  );

const isComparePercentMetric = (string: string) =>
  [
    'wtd-compare-percent',
    'mtd-compare-percent',
    'ytd-compare-percent',
    'today-compare-percent',
  ].includes(string);

const isCurrentMetroc = (string: string) =>
  ['wtd', 'mtd', 'ytd', 'today'].includes(string);

type AutoneTableFooterType = {
  isLoading: boolean;
  tableConfig: TableConfigType<{ dataRow: RetailPerformanceData }>[];
  data: RetailPerformanceData[];
  metric: string;
  name: string;
};

const AutoneTableFooter = ({
  isLoading,
  tableConfig,
  data,
  metric,
  name,
}: AutoneTableFooterType) => {
  const theme = useTheme();
  const { calculationConfig } = store.getState().config;
  const calcType = isKeyInType(calculationConfig, name)
    ? calculationConfig[name]
    : null;

  const borderRow = `1px solid ${theme.palette.grey[100]}`;

  const renderCell = (
    data: RetailPerformanceData[],
    dataKey: string | undefined,
  ) => {
    if (!dataKey) return null;

    if (calcType === 'mix') {
      if (isCompareMetric(dataKey)) {
        return <Label>0 pp</Label>;
      }

      return '100%';
    }

    if (isCompareMetric(dataKey)) {
      return (
        <Compare
          dataRow={data}
          dataKey={
            dataKey && dataKey.includes('today')
              ? `today-previous`
              : `${dataKey.substring(0, 3)}-previous`
          }
          metric={metric}
          currentKey={
            dataKey && dataKey.includes('today')
              ? 'today'
              : dataKey.substring(0, 3)
          }
        />
      );
    }

    if (isComparePercentMetric(dataKey)) {
      return (
        <ComparePercent
          dataRow={data}
          currentKey={
            dataKey && dataKey.includes('today')
              ? 'today'
              : dataKey.substring(0, 3)
          }
          compareKey={
            dataKey && dataKey.includes('today')
              ? `today-previous`
              : `${dataKey.substring(0, 3)}-previous`
          }
          totalSum
        />
      );
    }

    if (isCurrentMetroc(dataKey)) {
      return <Current dataRow={data} dataKey={dataKey} metric={metric} />;
    }

    // EXPLORER CONFIGS
    if (dataKey === 'primary_sales_val' || dataKey === 'comparison_sales_val') {
      return <Current dataRow={data} dataKey={dataKey} metric="value" />;
    }

    if (dataKey === 'primary_sales_vol' || dataKey === 'comparison_sales_vol') {
      return <Current dataRow={data} dataKey={dataKey} metric="volume" />;
    }

    if (dataKey === 'difference_sales_val') {
      return (
        <Compare
          dataRow={data}
          dataKey="comparison_sales_val"
          metric="value"
          currentKey="primary_sales_val"
        />
      );
    }

    if (dataKey === 'difference_sales_vol') {
      return (
        <Compare
          dataRow={data}
          dataKey="comparison_sales_vol"
          metric="volume"
          currentKey="primary_sales_vol"
        />
      );
    }

    if (dataKey === 'percentage_difference_sales_val') {
      return (
        <ComparePercent
          dataRow={data}
          currentKey="primary_sales_val"
          compareKey="comparison_sales_val"
          calculationType="explorer"
        />
      );
    }

    if (dataKey === 'percentage_difference_sales_vol') {
      return (
        <ComparePercent
          dataRow={data}
          currentKey="primary_sales_vol"
          compareKey="comparison_sales_vol"
          calculationType="explorer"
        />
      );
    }

    return null;
  };

  return (
    <TableRow>
      <TableCell
        sx={{
          pt: 1,
          borderTop: borderRow,
          zIndex: '9999',
          position: 'sticky',
          left: 0,
          background: 'white',
          color: `${theme.palette.text.primary}`,
        }}
        align="left"
      >
        {isLoading ? (
          <Skeleton height={30} width={80} />
        ) : (
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Total
          </Typography>
        )}
      </TableCell>
      {tableConfig &&
        tableConfig
          .slice(1)
          .filter((d) => d.active)
          .map(({ dataKey }) => {
            return (
              <TableCell
                key={dataKey}
                align="left"
                sx={{
                  pt: 1,
                  borderTop: borderRow,
                  zIndex: '99',
                  textAlign: 'right',
                  position: 'sticky',
                  left: 0,
                  background: 'white',
                  fontWeight: 'bold',
                  color: `${theme.palette.text.primary}`,
                }}
              >
                {isLoading ? (
                  <Skeleton height={30} width={80} />
                ) : (
                  data && renderCell(data, dataKey)
                )}
              </TableCell>
            );
          })}
    </TableRow>
  );
};

export default AutoneTableFooter;
