import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedDate: undefined,
  dateRanges: undefined,
};

export const retailCalendarSlice = createSlice({
  name: 'retailCalendar',
  initialState,
  reducers: {
    SET_SELECTED_DATE: (state, action) => ({
      ...state,
      selectedDate: action.payload,
    }),
    SET_DATE_RANGES: (state, action) => ({
      ...state,
      dateRanges: action.payload,
    }),
  },
});

export const { SET_SELECTED_DATE, SET_DATE_RANGES } =
  retailCalendarSlice.actions;

export default retailCalendarSlice.reducer;
