import { orderBy } from 'lodash';

import { countryDict } from '../getCountryDict';

import { defaultSort } from './defaultSort';

export const tableSorter = (data, sortColumn, sortOrder) => {
  const convertToNumber = (calc) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isFinite(calc)) {
      return false;
    }
    return Number(calc) || false;
  };

  const comparePercentSort = (current, compare) => {
    const currentNum = 1 * current;
    const compareNum = 1 * compare;

    if (currentNum > compareNum) {
      return convertToNumber(Math.abs(1 - currentNum / compareNum));
    }
    return convertToNumber(-1 * Math.abs(1 - currentNum / compareNum));
  };

  if (sortColumn === 'Sales.retailWeek') {
    return orderBy(
      data,
      (item) =>
        `${item?.['Sales.retailWeek']?.slice(-4)} ${
          item?.['Sales.retailWeek']
        }`,
      [sortOrder],
    );
  }
  if (
    sortColumn === 'today' ||
    sortColumn === 'wtd' ||
    sortColumn === 'wtd-avt' ||
    sortColumn === 'wtd-upt' ||
    sortColumn === 'mtd' ||
    sortColumn === 'mtd-avt' ||
    sortColumn === 'mtd-upt' ||
    sortColumn === 'ytd' ||
    sortColumn === 'ytd-avt' ||
    sortColumn === 'ytd-upt' ||
    sortColumn === 'Sales.totalSalesValue' ||
    sortColumn === 'Sales.totalSalesQuantity' ||
    sortColumn === 'Inventory.currentOnHandValue' ||
    sortColumn === 'Inventory.currentOnHandQuantity'
  ) {
    return orderBy(
      data,
      // eslint-disable-next-line arrow-body-style
      (o) => {
        return convertToNumber(o?.[sortColumn]);
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'today-compare') {
    return orderBy(
      data,
      (o) => convertToNumber((o?.today || 0) - (o?.['today-compare'] || 0)),
      [sortOrder],
    );
  }

  if (sortColumn === 'wtd-compare') {
    return orderBy(
      data,
      (o) => convertToNumber((o?.wtd || 0) - (o?.['wtd-compare'] || 0)),
      [sortOrder],
    );
  }

  if (sortColumn === 'mtd-compare') {
    return orderBy(
      data,
      (o) => convertToNumber((o?.mtd || 0) - (o?.['mtd-compare'] || 0)),
      [sortOrder],
    );
  }

  if (sortColumn === 'ytd-compare') {
    return orderBy(
      data,
      (o) => convertToNumber((o?.ytd || 0) - (o?.['ytd-compare'] || 0)),
      [sortOrder],
    );
  }

  if (sortColumn === 'today-compare-percent') {
    return orderBy(
      data,
      (o) => comparePercentSort(o?.today, o?.['today-compare']),
      [sortOrder],
    );
  }

  if (sortColumn === 'wtd-compare-percent') {
    return orderBy(
      data,
      (o) => comparePercentSort(o?.wtd, o?.['wtd-compare']),
      [sortOrder],
    );
  }

  if (sortColumn === 'mtd-compare-percent') {
    return orderBy(
      data,
      (o) => comparePercentSort(o?.mtd, o?.['mtd-compare']),
      [sortOrder],
    );
  }

  if (sortColumn === 'ytd-compare-percent') {
    return orderBy(
      data,
      (o) => comparePercentSort(o?.ytd, o?.['ytd-compare']),
      [sortOrder],
    );
  }

  if (sortColumn === 'Ticket.shippingCountry') {
    return orderBy(data, (o) => countryDict[o?.['Ticket.shippingCountry']], [
      sortOrder,
    ]);
  }

  if (sortColumn === 'primary_sales_val') {
    return orderBy(data, (o) => convertToNumber(o?.primary_sales_val), [
      sortOrder,
    ]);
  }

  if (sortColumn === 'comparison_sales_val') {
    return orderBy(data, (o) => convertToNumber(o?.comparison_sales_val), [
      sortOrder,
    ]);
  }

  if (sortColumn === 'primary_sales_vol') {
    return orderBy(data, (o) => convertToNumber(o?.primary_sales_vol), [
      sortOrder,
    ]);
  }

  if (sortColumn === 'comparison_sales_vol') {
    return orderBy(data, (o) => convertToNumber(o?.comparison_sales_vol), [
      sortOrder,
    ]);
  }

  if (sortColumn === 'difference_sales_val') {
    return orderBy(
      data,
      (o) =>
        convertToNumber(
          (o?.primary_sales_val || 0) - (o?.comparison_sales_val || 0),
        ),
      [sortOrder],
    );
  }

  if (sortColumn === 'difference_sales_vol') {
    return orderBy(
      data,
      (o) =>
        convertToNumber(
          (o?.primary_sales_vol || 0) - (o?.comparison_sales_vol || 0),
        ),
      [sortOrder],
    );
  }

  if (sortColumn === 'percentage_difference_sales_val') {
    return orderBy(
      data,
      (o) => comparePercentSort(o?.primary_sales_val, o?.comparison_sales_val),
      [sortOrder],
    );
  }

  if (sortColumn === 'percentage_difference_sales_vol') {
    return orderBy(
      data,
      (o) => comparePercentSort(o?.primary_sales_vol, o?.comparison_sales_vol),
      [sortOrder],
    );
  }

  return defaultSort(data, [sortColumn], [sortOrder]);
};
