/* eslint-disable complexity */
import { fISOToCalendarDate, fNumber, fPercent } from '@autone/utils';

import { DATE_KEYS } from '../../../config/consts';

// CURRENT AND COMPARES NEED TO BE A NUMBER

export const current = (dataRow, dataKey, metric) => {
  if (metric === 'value') {
    return dataRow && (dataRow[dataKey] ? dataRow[dataKey] * 1 : 0);
  }
  if (metric === 'volume') {
    return dataRow && (dataRow[dataKey] ? dataRow[dataKey] * 1 : 0);
  }

  // eslint-disable-next-line no-console
  return console.error('No matching metric');
};

export const compare = (dataRow, dataKey, type) => {
  // convert strings to number
  const current =
    dataRow && Number.isNaN(1 * dataRow[type]) ? 0 : 1 * dataRow[type] || 0;
  const compare =
    dataRow && Number.isNaN(1 * dataRow[dataKey])
      ? 0
      : 1 * dataRow[dataKey] || 0;

  return dataRow && current != null && compare != null
    ? current - compare
    : 'nm';
};

// returns formatted value as not needed as an input to any other calcs
export const comparePercent = (dataRow, type, totalSum = false) => {
  // convert strings to number
  const current = (dataRow && 1 * dataRow[type]) || 0;

  // added a boolean to switch calc to 'previous' if a total is being calculated
  let compare;
  if (totalSum) {
    compare = dataRow && 1 * dataRow[`${type}-previous`];
  } else {
    compare = dataRow && 1 * dataRow[`${type}-compare`];
  }

  return dataRow &&
    current &&
    compare &&
    !Number.isNaN(current) &&
    !Number.isNaN(compare)
    ? current > compare
      ? `+${fPercent(Math.abs(1 - current / compare))}`
      : `-${fPercent(Math.abs(1 - current / compare))}`
    : 'nm';
};

export const comparePercentExplorer = (dataRow, currentKey, compareKey) => {
  // convert strings to number
  const current = (dataRow && 1 * dataRow[currentKey]) || 0;
  const compare = (dataRow && 1 * dataRow[compareKey]) || 0;
  // added a boolean to switch calc to 'previous' if a total is being calculated

  return dataRow &&
    current &&
    compare &&
    !Number.isNaN(current) &&
    !Number.isNaN(compare)
    ? current > compare
      ? `+${fPercent(Math.abs(1 - current / compare))}`
      : `-${fPercent(Math.abs(1 - current / compare))}`
    : 'nm';
};

export const mixCurrent = (dataRow, dataKey, totals) => {
  const current = dataRow && 1 * dataRow[dataKey];
  if (current) {
    return current && totals && fPercent(current / totals[dataKey]);
  }
  return 'nm';
};

export const mixCompare = (dataRow, dataKey, type, totals) => {
  const current = (dataRow && 1 * dataRow[type]) || 0;
  const compare = (dataRow && 1 * dataRow[dataKey]) || 0;

  const currentMix = current && totals && (current / totals[type]) * 100;
  const compareMix =
    compare && totals && (compare / totals[`${type}-previous`]) * 100;

  return fNumber(currentMix - compareMix);
};

export const formatDateKeys = (item) => {
  const itemKeys = Object.keys(item);

  return itemKeys.reduce((acc, key) => {
    if (DATE_KEYS.includes(key)) {
      acc[key] = fISOToCalendarDate(item[key]);
    } else {
      acc[key] = item[key];
    }
    return acc;
  }, {});
};
