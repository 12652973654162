import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  primaryRangeObject: undefined,
  comparisonRangeObject: undefined,
} as {
  primaryRangeObject: { endDate: string; startDate: string } | undefined;
  comparisonRangeObject: { endDate: string; startDate: string } | undefined;
};

export const explorerSlice = createSlice({
  name: 'explorer',
  initialState,
  reducers: {
    UPDATE_PRIMARY_RANGE: (state, action) => ({
      ...state,
      primaryRangeObject: action.payload,
    }),
    UPDATE_COMPARISON_RANGE: (state, action) => ({
      ...state,
      comparisonRangeObject: action.payload,
    }),
  },
});

export const { UPDATE_PRIMARY_RANGE, UPDATE_COMPARISON_RANGE } =
  explorerSlice.actions;

export default explorerSlice.reducer;
