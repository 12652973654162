import { Modal, TableTabs } from '@autone/ui';
import { capitalizeFirstLetter } from '@autone/utils';
import { Box, Divider, Tab, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { modalLocationConfig, modalSizeConfig } from '../../config';

import { ProductModalTable } from '.';

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 64,
  height: 64,
  objectFit: 'contain',
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadiusSm,
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MENU_OPTIONS = [
  { value: 'size', label: 'Size' },
  { value: 'location', label: 'Location' },
];

const ProductCardModal = ({ open, handleClose, selectedProduct }) => {
  const [selected, setSelected] = useState('size');

  const handleTabClick = (event, newValue) => {
    setSelected(newValue);
  };

  const configData = {
    location: {
      dimension: 'Location.description',
      config: modalLocationConfig,
    },
    size: {
      dimension: 'Sku.sizeId',
      config: modalSizeConfig,
    },
  };

  const {
    'Product.product_id': productId,
    'Product.styleDescription': productStyleDesc,
    'Product.colorDescription': productColorDesc,
    'Image.url': imageUrl,
  } = selectedProduct || {};

  return (
    <Modal
      open={open}
      onClose={() => handleClose(productId)}
      closeButton={false}
      aria-labelledby="modal-product-card"
      aria-describedby="modal-product-card-details"
      modalSx={{ minWidth: '40%' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            pl: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ThumbImgStyle alt="product-image" src={imageUrl} />
          <Box
            sx={{
              display: 'block',
            }}
          >
            <Typography component="div" variant="body">
              {capitalizeFirstLetter(productStyleDesc)}
            </Typography>
            <Typography component="div" variant="body">
              {capitalizeFirstLetter(productColorDesc)}
            </Typography>
            <Typography
              component="div"
              variant="subtitle"
              sx={{ color: '#637381' }}
            >
              {productId}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <TableTabs
            value={selected}
            onChange={handleTabClick}
            aria-label="basic tabs example"
          >
            {MENU_OPTIONS.map((option) => (
              <Tab
                key={option.value}
                value={option.value}
                label={<Box sx={{ px: 0.2 }}>{option.label}</Box>}
                {...a11yProps(option.value)}
              />
            ))}
          </TableTabs>
          <Divider />
          <ProductModalTable
            selected={selected}
            dimension={selected && configData[selected].dimension}
            config={selected && configData[selected].config}
            selectedProductId={selectedProduct['Product.product_id']}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductCardModal;

ProductCardModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedProduct: PropTypes.object,
};
